import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaTags from '../components/MetaTags/MetaTags'
import PageLayout from '../components/PageLayout/PageLayout'
import { RichText } from '../components/RichText/RichText'
import { getMetaTags } from '../utils/metaTags'
import MainZoneRenderer from '../zones/MainContentZone'
import styles from './dark.module.scss'

export default function LayoutDark({ pageContext, data, location }) {
  const zones = data.strapiGQL.page.contentZones
  const variation = location.pathname.includes('balancing-the-equation')
    ? 'balancingTheEquation'
    : undefined

  return (
    <PageLayout theme="dark">
      <MetaTags {...getMetaTags(pageContext)} />
      <Container>
        <header className={styles.header}>
          <div className={styles.images}>
            <StaticImage
              src="../images/02-card-dark.png"
              alt="Algbra Card"
              role="presentation"
              layout="constrained"
              width={389}
              height={488}
              quality={100}
              placeholder="blurred"
              className={classNames(styles.item, styles.card_2)}
            />
            <StaticImage
              src="../images/01-card-light.png"
              alt="Algbra Card"
              role="presentation"
              layout="constrained"
              width={362}
              height={463}
              quality={100}
              placeholder="blurred"
              className={classNames(styles.item, styles.card_1)}
            />
            <div className={classNames(styles.light, styles.light_1)}></div>
            <div className={classNames(styles.light, styles.light_2)}></div>
          </div>
          <Heading
            level={1}
            type="xxl"
            className={classNames(styles.title, styles[variation])}
          >
            {pageContext.title}
          </Heading>
        </header>
      </Container>
      <Container>
        {data.strapiGQL.page.subtitle && (
          <Whitespace type="md">
            <RichText theme="dark" content={data.strapiGQL.page.subtitle} />
          </Whitespace>
        )}
      </Container>
      {zones && <MainZoneRenderer zones={zones} location={location} />}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      page(id: $id) {
        ...StrapiGQL_PagesWithContentZones
      }
    }
  }
`
